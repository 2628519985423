import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog35/image1.jpg"
import image2 from "../../images/blog35/table.png"








export default function HowToIncreaseClubHeadSpeed(){
    return(
        <BlogPostOld
            src={image}
            title={"All Things Kettlebell: A Brief Intro"}
            summary={"Kettlebells (KB) entered the US market in the 90’s and were made popular by Pavel Tsatsouline a former Soviet Special Forces trainer who migrated from Belarus. Because it is simply a weight with a handle on it the origins can be traced back to tools used on farms and boats hundreds of years earlier.  \n"}
            date={"Jun. 6, 2023"}
            author={"Dr. Ben DeLuca, DC"}

        >
            <div>
                <p><strong>Brief Kettlebell history&nbsp;</strong></p>
                <p>Kettlebells (KB) entered the US market in the 90’s and were made popular by<a href="http://www.strongfirst.com/" target="_blank" rel="noopener"> Pavel Tsatsouline</a> a former Soviet Special Forces trainer who migrated from Belarus. Because it is simply a weight with a handle on it the origins can be traced back to tools used on farms and boats hundreds of years earlier.&nbsp;&nbsp;</p>
                <p><strong>How the weight works</strong></p>
                <p>The kettlebell's&nbsp; unique shape allows versatility in a small space, which makes it an awesome tool for strength, conditioning, and rehab. Traditional Russian bells come in 11 sizes and are measured in KG (kilograms). They start at 8 kg (17.6 lbs) and increase in weight to 48 kg (105.3 lbs). In the Kettle bell community the 48 kg bell is affectionately known as the beast. Kettlebells increase by 4 kg or 8.8 lbs making each increase in weight meaningful to the user thus a great key performance indicator (KPI) of improvement in strength. Several American manufacturers produce smaller and larger bell sizes, with different handle options and are measured in lbs. Below is a table with traditional KB sizes in kilograms (KG) and the imperial conversions to pounds (LBS).&nbsp;</p>
                <div>
                    <img src={image2} alt={"table of data"}/>
                </div>
                <p><strong>How We Use the Kettlebell</strong></p>
                <p>I’m personally a traditionalist when it comes to kettlebells and stick with the Russian shapes and sizes. This is my bias because a large portion of our clientele are injured and when teaching someone who is fearful of weight how to get back to lifting things again, it is helpful for them to see smaller numbers that don’t elicit as much concern or fear of damage. Many of my low back pain patients can deadlift a 106 lb kettlebell, though they don’t even know it. This includes many of our youth athletes and seniors.&nbsp;</p>
                <p>Because the lightest KB is an 8 kg (17.6lbs) ball of iron this can challenge a person's perception of what they can and should lift. There stands a conundrum that many face when experiencing pain or recovering from an injury; to get stronger, preparing their body for life’s demands or to limit the amount of activity or weight during an exercise to protect the injured area.&nbsp;</p>
                <p>We generally understand we need to get stronger but oftentimes we limit our ability to do so by managing ourselves away from load as a result of the fear that lingers every time we feel discomfort. This is perpetuated by those who tell us how risky certain exercises or “heavy” weights can be which are then cemented by anecdotes by friends and family. If you are fortunate enough to have access to a primary care physician in hopes to get some help, you are often told to stay away from specific exercises (and because you have felt discomfort in the past doing these, it is all the validation you need).</p>
                <p>The belief that one should limit their load to an arbitrary number or not squat or deadlift in fear of injury is a “zombie belief” rooted from flawed logic without any strong evidence from the current literature in sport’s medicine and musculoskeletal pain management.</p>
                <p>HURT DOES NOT ALWAYS EQUAL HARM! The risk with spreading these zombie beliefs is now people are encouraged to continue to use bands and 5-10 lbs dumbbells that do not provide enough stimulus to promote POSITIVE changes to the body. If it doesn’t challenge you, it doesn’t change you.&nbsp;</p>
                <p>You are not fragile, the human body is resilient and is meant to adapt and overcome the challenges we place on it.&nbsp;&nbsp;&nbsp;</p>
                <p>This is why at Kinetic Impact we recommend following a program that grows organically with you. It's not the weight you lift that hurts you, it's the weight you're not prepared to lift that poses the threat.&nbsp;</p>
                <p>Many times when people experience excessive soreness beyond what is expected it is a result of too much too soon: too many reps, too much weight, too many days a week, or too many different exercises.&nbsp;</p>
                <p>This can be unmotivating, a hindrance to progress, and feel harmful (even if it's not). The key to proper progression and meaningful long term results involves communication and a partnership between the coach/trainer/clinician and the patient/client. Consistency for a beginner has more value than intensity. Finding a safe entry point, with realistic goals and timelines will yield more long term results and is key to success.</p>

                    <div className={"flex flex-row justify-center flex-wrap"}>
                        <a className={"lg:px-4 !text-white"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call or Text Us and get started!</button></a>
                        <a className={"lg:px-4 !text-white"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                    </div>

                <p><strong>If&nbsp; flare ups occur do not abort the plan. Managing the symptoms and adjusting the strategy to stay on course for the long term goals is vital. This is true whether you use a kettlebell, barbell, dumbbell, run, walk, play golf, play basketball… anything and everything in between.</strong></p>
            </div>

        </BlogPostOld>

    )
}